import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { PageLayout } from './components/Authentication/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import axios from './axiosConfig';
import './global.css';
import { truncateEmail } from './Utilities/utils';


const MainContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const onInsertLog = async () => {    
    try {      
      if (graphData && accounts.length > 0) {            
        if (process.env.REACT_APP_ENV === 'prod') {
        const sessionUrl = `/insertSessionData?&adAccount=${encodeURIComponent(truncateEmail(graphData.mail,50))}&adName=${encodeURIComponent(graphData.displayName)}&adTitle=${encodeURIComponent(graphData.jobTitle || '')}&adLocation=${encodeURIComponent(graphData.officeLocation || '')}&emailAddress=${encodeURIComponent(graphData.mail)}&adUniqueId=${encodeURIComponent(graphData.id)}`;        
        const response = await axios.post(sessionUrl, null, {
          headers: {
            Authorization: accounts[0].idToken,
          },
        });           
      }
    }
    } catch (error) {console.error('Error:', error);};
  }

  useEffect(() => {
    function requestProfileData() {
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        callMsGraph(response.accessToken).then(response => {
          
          
          setGraphData(response);             

        }).catch(error => {
          // Handle error in fetching data from MS Graph
          console.error('Error fetching data from MS Graph:', error);
        });
      }).catch(error => {
        // Handle error in acquiring token
        console.error('Error acquiring token:', error);
      });
    }

    if (accounts.length > 0) {
      requestProfileData();
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (graphData !== null) {
      onInsertLog();
    }
  }, [graphData]);

  return (
    <div className="App">
      <AuthenticatedTemplate>       
        <Layout>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Routes>
        </Layout>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <PageLayout />
      </UnauthenticatedTemplate>
    </div>
  );
};

function App() {
  return (
    <MainContent />
  );
}

export default App;
