import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Window } from "@progress/kendo-react-dialogs";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { TextBox, NumericTextBox, TextArea, Input } from "@progress/kendo-react-inputs";
import { Label, Hint } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { SvgIcon } from '@progress/kendo-react-common';
import { xIcon } from '@progress/kendo-svg-icons';
import useProfileData from '../services/ProfileDataService';
import { Tooltip as KendoTooltip} from "@progress/kendo-react-tooltip";
import { Upload } from "@progress/kendo-react-upload";
import Modal from './Modal';
import './SidePanel.css';
import Toast from './ToastNotification';
import axios from '../axiosConfig';
import CustomToast from './CustomToastError';
import { useMsal } from "@azure/msal-react";
import {ListView} from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { getCurrentLine,subscribeToCurrentLineChanges  } from '../services/CurrentLineService';
import { truncateEmail, formatDate } from '../Utilities/utils';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const requiredValidator = value => value ? '' : 'This field is required.';
// const commentValidator = (value) => {
//   if (!value) {
//     return 'Comments cannot be empty';
//   } else if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
//     return 'Only alphanumeric characters are allowed';
//   } 
// };

const commentValidator = value => value ? '' : 'Comments cannot be empty.';

export const FormTextArea = (fieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, hint, disabled, optional, ...others} = fieldRenderProps;
  const max = 500;

  return (
    <FieldWrapper>
      <div className={"k-form-field-wrap"}>
        <TextArea
          style={{ height: '100px'}}
          valid={valid}
          id={id}
          disabled= {disabled}
          maxLength={max}
          rows={10}
          {...others}
        />
        <Hint direction={"end"}>
          {fieldRenderProps.value !== undefined ? `${fieldRenderProps.value.length} / ${max}` : `0 / ${max}`}
        </Hint>
      </div>
    </FieldWrapper>
  );
};

const CommentsForm = (props) => {
  const { formRenderProps, disabled, reset, setReset} = props;
  const [toast, setToast] = useState(false);

  const [idtoast, setIdtoast] = useState(1); 

  useEffect(() => {
    if (formRenderProps.modified === true && formRenderProps.errors.Comment != '' && reset=== false){
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: formRenderProps.errors.Comment, type: 'error' });
    };
  }, [formRenderProps.errors.Comment != '']);

  const onChangeComments = () => {
    setReset(false);
  }

  return (
    <FormElement style={{ padding: "20px" }}>
      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <FieldWrapper>
        <div className="k-form-field-wrap">
          <Field
            className='comments-box'
            name={"Comment"}
            component={FormTextArea}
            labelClassName={"k-form-label"}
            label={"kk"}
            validator={commentValidator}
            disabled={disabled}
            onChange={onChangeComments}
          />
        </div>
      </FieldWrapper>
      <DialogActionsBar layout="end">
        <Button
          className='primary-button'
          type={"submit"}
          disabled={formRenderProps.errors.Comment != ''}
          themeColor={"primary"}>
          Add
        </Button>
      </DialogActionsBar>
    </FormElement>
  );
};


const CustomField = fieldRenderProps => {
  const { validationMessage, touched, visited, modified, valid, value, onChange, onFocus, onBlur, label, id, disabled, type, item, ...others } = fieldRenderProps;
  const tooltip = React.useRef(null);  



  function validateStatusChange(selectedStatus) {

    //Disable if order has not being assigned
    if (item.esoiStatusDesc === 'Released' || item.designerDetail === 'Not Assigned') {
      return false;
    }

    if(type === 'SPC')
    {
      const statusOrder = [
        'Not started',
        'Order Processing',
        'Ready for Drafting',
        'WIP',
        'Ready for Check',
        'Submitted',
        'Released'
      ];

      const currentIndex = statusOrder.indexOf(item.esoistatusDesc);    
      const selectedIndex = statusOrder.indexOf(selectedStatus);        
    
      if (selectedIndex === -1 || selectedIndex !== currentIndex + 1) {
        //if next status is not correct return -1
        return false;
      }    
      return true;
    } else {
      
      if (item.esoistatusDesc === 'Released') {
        return false;
      } else {
        return true;
      }
    }
    
  }

  return (
    <div>
      <Label editorId={id} className={"k-form-label"}>
        {label}
      </Label>
      <div
        style={{ display: 'inline-flex', gap: '8px', flexDirection: 'row', flexWrap: 'wrap'}}
        onMouseOver={(event) =>
          tooltip.current && tooltip.current.handleMouseOver(event)
        }
        onMouseOut={(event) =>
          tooltip.current && tooltip.current.handleMouseOut(event)
        }
      >
      <button
        title="Not Started"
        type={"button"}
        // disabled={true}
        disabled={!(item.esoistatusDesc === "Not Started")}
        onClick={() => onChange({ value: "Not Started" })}
        style={{cursor: item.esoistatusDesc === "Not Started" ? 'not-allowed' :  'default' }}
        className={`roboto-15-400 
        ${item.esoistatusDesc === "Not Started" ? 'ns-button' : 'bar-button blue-text'}`}
      >
        Not Started
      </button>
      {type === 'SPC' && (
        <button
          title="Order Processing"
          type={"button"}
          disabled={!validateStatusChange('Order Processing') && !(item.esoistatusDesc === "Order Processing")}
          onClick={() => onChange({ value: "Order Processing" })}
          style={{cursor: item.esoistatusDesc === "Order Processing" ? 'not-allowed' : !validateStatusChange('Order Processing') ? 'default' : 'pointer'}}
          className={`roboto-15-400 
          ${item.esoistatusDesc === "Order Processing" ? 'op-button' : 'bar-button blue-text'}`}
        >
          Order Processing
        </button>
      )}

      {type === 'SPC' && (
      <div style={{ display: 'inline-flex', gap: '8px', flexDirection: 'row', flexWrap: 'wrap'}}>
        <button
          title="Ready for Drafting"
          type={"button"}
          disabled={!validateStatusChange('Ready for Drafting') && !(item.esoistatusDesc === "Ready for Drafting")}
          onClick={() => onChange({ value: "Ready for Drafting" })}
          style={{cursor: item.esoistatusDesc === "Ready for Drafting" ? 'not-allowed' : !validateStatusChange('Ready for Drafting') ? 'default' : 'pointer'}}
          className={`roboto-15-400 
          ${item.esoistatusDesc === "Ready for Drafting" ? 'RFD-button' : 'bar-button blue-text'}`}
        >
          Ready for Drafting
        </button>
        <button
          title="Work in progress"
          type={"button"}
          disabled={!validateStatusChange('WIP') && !(item.esoistatusDesc === "WIP")}
          onClick={() => onChange({ value: "WIP" })}
          style={{cursor: item.esoistatusDesc === "WIP" ? 'not-allowed' : !validateStatusChange('WIP') ? 'default' : 'pointer'}}
          className={`roboto-15-400 
          ${item.esoistatusDesc === "WIP" ? 'wip-button' : 'bar-button blue-text'}`}
        >
          WIP
        </button>
        <button
          title="Ready for Check"
          type={"button"}
          disabled={!validateStatusChange('Ready for Check') && !(item.esoistatusDesc === "Ready for Check")}
          onClick={() => onChange({ value: "Ready for Check" })}
          style={{cursor: item.esoistatusDesc === "Ready for Check" ? 'not-allowed' : !validateStatusChange('Ready for Check') ? 'default' : 'pointer'}}
          className={`roboto-15-400 
          ${item.esoistatusDesc === "Ready for Check" ? 'RFCH-button text-white' : 'bar-button blue-text'}`}
        >
          Ready for Check
        </button>
      </div>
      )}
        <button
          title="Submitted"
          type={"button"}
          disabled={!validateStatusChange('Submitted') && !(item.esoistatusDesc === "Submitted")}
          onClick={() => onChange({ value: "Submitted" })}
          style={{cursor: item.esoistatusDesc === "Submitted" ? 'not-allowed' : !validateStatusChange('Submitted') ? 'default' : 'pointer'}}
          className={`roboto-15-400
          ${item.esoistatusDesc === "Submitted" ? 's-button' : 'bar-button blue-text'}`}
        >
          Submitted
        </button>
        <button
          title='Released'
          type={"button"}
          disabled={!validateStatusChange('Released') && !(item.esoistatusDesc === "Released")}
          onClick={() => onChange({ value: "Released" })}
          style={{cursor: item.esoistatusDesc === "Released" ? 'not-allowed' : !validateStatusChange('Released') ? 'default' : 'pointer'}}
          className={`roboto-15-400 
          ${item.esoistatusDesc === "Released" ? 'r-button text-white' : 'bar-button blue-text'}`}
        >
          Released
        </button>
        <KendoTooltip
          ref={tooltip}
          anchorElement="target"
          position="top"
        />
      </div>
    </div>
  );
};

const MyCustomButton = fieldRenderProps => {
  const { validationMessage, touched, visited, modified, valid, value, onChange, onFocus, onBlur, label, id, disabled, ...others } = fieldRenderProps;
  const { accounts } = useMsal();

  const onButtonClick = useCallback(() => {
    const newValue = value === 'Not Assigned' ? accounts[0].name : 'Not Assigned';    
    onChange({
      value: newValue
    });
    setAssigned(!assigned);
  }, [onChange, value]);

  const [assigned, setAssigned] = useState(value !== 'Not Assigned');

  let borderColorCase;
  let colorCase;
  if (!assigned && !disabled) {
    borderColorCase = '#0097E0';
    colorCase = '#0097E0';
  } else if (!assigned && disabled) {
    borderColorCase = '#828282';
    colorCase = '#828282';
  } else {
    borderColorCase = 'transparent';
    colorCase = '#4C4C4C';
  }

  return (
    <div onFocus={onFocus} onBlur={onBlur} style={{ width: '230px', marginBottom: '3px' }}>
      <Label editorId={id} className={"k-form-label"} style={{height: '28px', alignItems: 'center', display: 'flex'}}>
        {label}
      </Label>
      <div style={{ justifyContent: 'space-between', height: '30px', display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', width: '140px' }}>
        <button 
          title = {value == 'Not Assigned' ?  'Assign to me' : value}
          onClick={onButtonClick} 
          type={"button"}
          className='secondary-button roboto-15-500'
            style={{
              flex: 1,
              justifyContent: assigned ? 'flex-start' : 'center',
              background: 'transparent',
              color: colorCase,
              fontWeight: assigned ? 400 : 500,
              borderColor: borderColorCase,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              padding: assigned ? '0px'  : '12px',
              cursor: assigned || disabled ? 'default' : 'pointer'
            }}
            disabled={assigned || disabled}
          >
          {value == 'Not Assigned' ?  'Assign to me' : value}
        </button>
        {!disabled && ( 
        <Button
            disabled= {disabled}
            onClick={onButtonClick}
            type={"button"}
            style={{
              justifyContent: 'flex-end',
              padding: '0px',
              paddingBottom: '2px',
              display: assigned ? 'block' : 'none',
              width: '24px',
              height: '24px',
              borderRadius: '180px',
              backgroundColor: '#F2F2F2',
              border: 'none',
            }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
          </Button>
        )}
        </div>
      {/* {visited && validationMessage && <Error>{validationMessage}</Error>} */}
    </div>
  );
};

const minValueValidator = (value) => (value >= 0 ? "" : "The value must be 0 or higher");

const NonNegativeNumericInput = (fieldRenderProps) => {
  const [toast, setToast] = useState(false);
  const [idtoast, setIdtoast] = useState(1);
  const { disabled2, validationMessage, visited, id, valid, label, showErrorMessage, myAssignedWorkInfo, ...others } = fieldRenderProps;
  const numericTextBoxRef = useRef(null);
  
  useEffect(() => {
    if (fieldRenderProps.modified === true && fieldRenderProps.value < 0) {
      setIdtoast(idtoast + 1);
      setToast({ showToast: false });
      setToast({ showToast: true, message: 'The value must be 0 or higher', type: 'error' });
    }        

    if (numericTextBoxRef.current && showErrorMessage && myAssignedWorkInfo?.isError) {
      numericTextBoxRef.current.focus();
    }
  }, [fieldRenderProps.validationMessage != ""]);


  return (
    <>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <Label editorId={id} editorValid={valid} className={"k-form-label"}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <NumericTextBox ref={numericTextBoxRef} spinners={disabled2} {...others} />
        {/* {visited && validationMessage && <Error>{validationMessage}</Error>} */}
      </div>
    </>
  );
};


const AssignationForm = (props) => {
  const { formRenderProps, cancelEdit, item, disabled, myAssignedWorkInfo, token } = props;
  const disabledDetail = formRenderProps.valueGetter('designerDetail') === "Not Assigned";
  const disabledChecker = formRenderProps.valueGetter('designerChecker') === "Not Assigned";  

  // const disabledDetailEst = formRenderProps.errors.detailEst == "";
  // const disabledDetailAct = formRenderProps.errors.orderNumber == "";
  // const disabledCheckerEst = formRenderProps.errors.checkerEst == "";
  // const disabledCheckerAct = formRenderProps.errors.orderNumberChecker == "";

  // const submit = formRenderProps.modified && ((!disabledDetail || !disabledChecker ) && (disabledDetailEst && disabledDetailAct && disabledCheckerEst && disabledCheckerAct));
  // const submit = formRenderProps.modified && ((disabledDetailEst && disabledDetailAct && disabledCheckerEst && disabledCheckerAct));
  const submit = formRenderProps.modified; //modified on 28/05/2024 as it didn't work on MAW
  const [showDiv, setShowDiv] = useState(true);
  const showErrorDetailer = !myAssignedWorkInfo?.isChecker;

  return (
    <FormElement className='roboto-15-500' style={{ margin: "24px 8px 0px 8px", textAlign: 'left' }}>
      <div className='assignation-container'>
        <Field
          label={'Status:'}
          name="esoistatusDesc"
          component={CustomField}
          disabled={disabled}
          type={item.itemType}
          item={item}
        />
      </div>
      {myAssignedWorkInfo && myAssignedWorkInfo?.isError &&(
      <div>
        <CustomToast
          showDiv={showDiv}
          message="You need to add the actual hours in order to change the order status."
          type="error"
          onClose={() => setShowDiv(false)}
        />         
      </div>
      )}
      <div className='assignation-container'>
        <Field
          name={"designerDetail"}
          label={"Designer for Detail"}
          component={MyCustomButton}
          validator={requiredValidator}
          disabled={disabled}
        />
        <div style={{ flexDirection: 'column', textAlign: 'left' }}>
          <div className='mt-2'>{'Est'}</div>
            <div className='roboto-15-400' style={{height: '36px', alignItems: 'center', display: 'flex', width: '80px'}}>
              {formRenderProps.valueGetter('detailEst')}
          </div>
        </div>
        <FieldWrapper>
          <Field className='roboto-15-400' name={"orderNumber"} component={NonNegativeNumericInput} showErrorMessage={!showErrorDetailer} myAssignedWorkInfo={myAssignedWorkInfo} label={"Actual"} validator={minValueValidator} disabled={disabledDetail || disabled} disabled2={!disabled}/>
        </FieldWrapper>
      </div>
      
      <div className='assignation-container' style={{ marginBottom: '16px', display: item.itemType !== 'FIN' ? '' : 'none' }}>
        <Field
          name={"designerChecker"}
          label={"Designer for checker"}
          component={MyCustomButton}
          validator={requiredValidator}
          disabled={disabled}
        />
        <div style={{ flexDirection: 'column', textAlign: 'left' }}>
          <div className='mt-2'>{'Est'}</div>
            <div className='roboto-15-400' style={{height: '36px', alignItems: 'center', display: 'flex', width: '80px'}}>
              {formRenderProps.valueGetter('checkerEst')}
          </div>
        </div>
        <FieldWrapper>
          <Field className='roboto-15-400' name={"orderNumberChecker"} component={NonNegativeNumericInput} showErrorMessage={showErrorDetailer} myAssignedWorkInfo={myAssignedWorkInfo} label={"Actual"} validator={minValueValidator} disabled={disabledChecker || disabled} disabled2={!disabled} />
        </FieldWrapper>
      </div>
      
      <div style={{ flexDirection: 'column', textAlign: 'left', marginTop: '16px'}}>
        <div>{'Hours left'}</div>
          <div className='roboto-15-400'>
            <Field
              name="hrsLeft"
              component={Input}
              disabled={true}
              style={{ textAlign: 'left', border: 'none', opacity: 1 }}
              className='roboto-15-400'
            />
        </div>
      </div>
      <DialogActionsBar layout="end">
        <Button className='secondary-button blue-text roboto-15-500' onClick={cancelEdit}>
          Close
        </Button>
        <Button
          className='primary-button'
          type={"submit"}
          themeColor={"primary"}
          disabled={!submit}
        >
          Save
        </Button>
      </DialogActionsBar>
    </FormElement>
  );
};


  const SidePanel = (props) => {
    const { visible, cancelEdit, onSubmit, item, onPriorityUpdate, onDesignerDetailChange, onDesignerCheckerChange, onDesignerDetailHrsChange, onDesignerCheckerHrsChange, disabled, myAssignedWorkInfo } = props;
    const [selected, setSelected] = useState(0);
    const [markPriority, setMarkPriority] = useState(item?.priority);
    const { token, userName, userEmail } = useProfileData(false);
  
    useEffect(() => {          
      const fetchHrssData = async () => {
        if (token) {
          try {                      
    
            const response = await axios.get(`/getOrderAssignation/${item.orderDetailId}`, {
              headers: {
                Authorization: token,
              },
            });
      
            const detailInfo = response.data.filter(item => item.employeeTitleId === 1);
            const checkInfo = response.data.filter(item => item.employeeTitleId === 2);  
    
            if (detailInfo.length > 0 ) {
              onDesignerDetailHrsChange(
                detailInfo[0].hoursActual || 0
              );
            }             

            if (checkInfo.length > 0) {
              onDesignerCheckerHrsChange(
                checkInfo[0].hoursActual || 0
              );
            }      
    
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
        else
        {
          console.error("Error fetching data:");
        }
      };  
    
      fetchHrssData();
    }, [token ]);

  const [changeOrdersDetails, setChangeOrdersDetails] = useState([]);
  const [changeOrderDate, setChangeOrderDate] = useState([]);

  const fetchChangeOrdersDetails = async () => {

    let valuesList = [];

    let mappedValues = {
      ESOI: item.esoi,
      SO: item.so,
      LongChanges: 0,
    };

    valuesList = [mappedValues];

    try {                  
      if (token) {
        const response = await axios.post(
          `/getChangeOrderDetails/`,
            valuesList, 
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setChangeOrdersDetails(response.data);

          if (response.data && response.data.length > 1) {
            let changeOrderString = response.data[1][0];

            if (typeof changeOrderString === 'string') {
                // Split the string by commas to get key-value pairs
                let keyValuePairs = changeOrderString.split(', ');

                // Create an object to hold the parsed values
                let changeOrderObject = {};

                // Iterate through the key-value pairs and add them to the object
                keyValuePairs.forEach(pair => {
                    let [key, value] = pair.split(': ');
                    changeOrderObject[key.trim()] = value.trim();
                });

                // Access the ChangeDate field and set it
                if (changeOrderObject.ChangeDate) {
                    setChangeOrderDate(changeOrderObject.ChangeDate);
                }
            }
        } 
    }
    } catch (error) {
      console.error('Error fetching change order details:', error);
    }
  };

  const changesText = changeOrdersDetails[2]?.slice(1)?.map(change => {
    // Remove "12:00:00 AM" time from date statements
    change = change.replace(/\s12:00:00 AM/g, '');
    // Remove . at the end of sentences and extra whitespace
    return change.trim().replace(/\.$/, '');
  }).join(', ');

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }
  ));

  useEffect(() => {
    const fetchHrssData = async () => {
      if (token) {
        try {                      
  
          const response = await axios.get(`/getOrderAssignation/${item.orderDetailId}`, {
            headers: {
              Authorization: token,
            },
          });
    
          const detailInfo = response.data.filter(item => item.employeeTitleId === 1);
          const checkInfo = response.data.filter(item => item.employeeTitleId === 2);  
  
          if (detailInfo.length > 0 ) {
            onDesignerDetailHrsChange(
              detailInfo[0].hoursActual || 0
            );
          }             

          if (checkInfo.length > 0) {
            onDesignerCheckerHrsChange(
              checkInfo[0].hoursActual || 0
            );
          }      
  
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      else
      {
        console.error("Error fetching data:");
      }
    };  
  
    fetchHrssData();
    fetchChangeOrdersDetails();
  }, [token]);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleChange = async () => {

    const updatedData = {
      ...item,
      priority: !markPriority, // change markPriority value
    };
     
    let errorMessage;
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    try {
      if (token && item.orderDetailId) {                
        const updateUrl = `/updatePriority/${encodeURIComponent(item.orderDetailId)}?isPriority=${encodeURIComponent(!item?.isPriority)}&Author=${encodeURIComponent(userEmail)}`;

        const response = await axios.put(
          updateUrl,
          null, 
          {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json', 
            }
          }
        );     
        
        if (response.status === 200) {
          

          if(unmark){
            onPriorityUpdate(true);
          }
          else{
            onPriorityUpdate(false);
          }
        }
      }
    } catch (error) {
      if (error.response) {

        const status = error.response.status;
        const errorMessages = {
          401: 'Expired Token',
        };

        errorMessage = errorMessages[status] || 'An error occurred';
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      setToast({ showToast: true, message: errorMessage, type: 'error' });

    }

    // Llama a handleUpdateAndChange para manejar la actualización tanto en el frontend como en el backend
    onSubmit(updatedData, false);
  };

  const [unmark, setUnmark] = useState(item.isPriority);

  const unmarkP = () => {
    setUnmark(!unmark);
    handleChange();
  }

  const [downloadSummaryVar, setDownloadSummaryVar] = useState(false);
  const downloadSummaryFunction = () => {
    //setDownloadSummaryVar(!downloadSummaryVar);
    generateSummary();
  }

  // ************** Generate summary file ************************************************
  // app.MapPost("/create/summaryfile", async (string orderNumber, string departmentsFolder, string departmentFolder, string orderNumFolder,
  //   string containerName, ETODbContext etoContext, OPSDbContext opsContext, HttpContext ctx) =>
  const generateSummary = async () => {
    let errorMessage;
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    try {
      if (token && item.so) {
        const saveUrl = `/create/summaryfile?&orderNumber=${encodeURIComponent(item.esoi)}&departmentsFolder=${encodeURIComponent(departmentsFolder)}&departmentFolder=${encodeURIComponent(departmentFolder)}&orderNumFolder=${encodeURIComponent(item.so)}&containerName=${encodeURIComponent(containerName)}`;

        const response = await axios.post(saveUrl, null,{
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          }
        });

        if (response.status === 200) {
          const summaryFileName = item.esoi + '_Summary.xlsx';

          const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(summaryFileName)}&departmentsFolder=${encodeURIComponent(departmentsFolder)}&departmentFolder=${encodeURIComponent(departmentFolder)}&orderNumFolder=${encodeURIComponent(item.so)}&containerName=${encodeURIComponent(containerName)}&isProfileImage=${encodeURIComponent(false)}`;
          try {
            const response = await axios.get(downloadUrl, {
              headers: {
                Authorization: token,
              },
              responseType: 'blob',
            });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.download = summaryFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            } catch (error) {
            if (error.response) {

              const status = error.response.status;
              const errorMessages = {
                401: 'Expired Token',
                500: 'The file does not exist on the blob storage'
              };

              errorMessage = errorMessages[status] || 'An error occurred';
            } else {
              errorMessage = error.message || 'An unexpected error occurred';
            }

            setToast({ showToast: true, message: errorMessage, type: 'error' });
          }
        }

        // setFilepath(uploadedFile.name);
        setToast({ showToast: true, message: "Your file has been created successfully", type: 'success' });
      }
    } catch (error) {
      if (error.response) {

        const status = error.response.status;
        const errorMessages = {
          400: 'The file already exists',
          401: 'Expired Token',
          500: 'The file already exists',
        };

        errorMessage = errorMessages[status] || 'An error occurred';
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      setToast({ showToast: true, message: errorMessage, type: 'error' });

    }
  };

  
  // ************** Download summary file ************************************************
  //app.MapGet("/download/blob", async (string fileName, string departmentsFolder, string departmentFolder, string orderNumFolder, string containerName, HttpContext ctx) =>
  const downloadSummary = async (isETOFile) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;
    let fileName = '';

    //choose what file it is
    if (!isETOFile) {
      fileName = files1[0].name;
    }
    else {
      fileName = files2[0].name;
    }

    const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(fileName)}&departmentsFolder=${encodeURIComponent(departmentsFolder)}&departmentFolder=${encodeURIComponent(departmentFolder)}&orderNumFolder=${encodeURIComponent(item.so)}&containerName=${encodeURIComponent(containerName)}`;
    try {
      const response = await axios.get(downloadUrl, {
        headers: {
          Authorization: token,
        },
        responseType: 'blob',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error.response) {

        const status = error.response.status;
        const errorMessages = {
          401: 'Expired Token',
          500: 'The file does not exist on the blob storage'
        };

        errorMessage = errorMessages[status] || 'An error occurred';
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      setToast({ showToast: true, message: errorMessage, type: 'error' });
    }
  };

  let backgroundColor;

  const statusColors = {
    'Order Processing': { backgroundColor: '#CDB8EB', fontColor: 'black' }, // op
    'Not Started': { backgroundColor: '#DEEFEF', fontColor: 'black' }, // ns
    'WIP': { backgroundColor: '#FFF890', fontColor: 'black' }, // wip
    'Submitted': { backgroundColor: '#9FF28F', fontColor: 'black' }, // s
    'Released': { backgroundColor: '#007F0E', fontColor: 'white' }, // r
    'default': { backgroundColor: 'transparent', fontColor: 'black' }, // Default
    'Ready for Check': { backgroundColor: '#0097E0', fontColor: 'white' }, //RFCH
    'Ready for Drafting': { backgroundColor: '#FFE7CD', fontColor: 'black' }, //RFD
  };

  const status = item.esoistatusDesc;
  const statusColor = statusColors[status] || statusColors['default'];

  let ECOTypeColor;
  let ECOTypeBorder;

  switch (item.ecotype) {
    case 'In-Cycle, must negotiate, include Build Date weeks':
      ECOTypeColor = '#FF4949'; //in cycle, must negotiate...
      break;
    case ('Awaiting Processing/BOM' || 'Sales Order, Possible Re-Process' || 'Configurator Processing'):
      ECOTypeColor = '#FFCC3D'; //configurator processing & sales order...
      break;
    case 'Already Built or Too Late':
      ECOTypeColor = 'white'; //future & already built...
      ECOTypeBorder = '1px solid #FF4949'
      break;
    default:
      backgroundColor = 'transparent';
      ECOTypeBorder = 'none'
      break;
  }

  const [download, setDownload] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [forceUpdate2, setForceUpdate2] = useState(false);
  const [downloadETO, setDownloadETO] = useState(false);
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const uploadRef1 = useRef(null);
  const uploadRef2 = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalPriorityVisible, setModalPriorityVisible] = useState(false);
  const [toast, setToast] = useState(null);

  const [filepath, setFilepath] = useState('valor_filepath');
  const [departmentsFolder, setDepartmentsFolder] = useState('Departments');
  const [departmentFolder, setDepartmentFolder] = useState('Roof');
  const [containerName, setContainerName] = useState('engineerorderfiles');
  const [idtoast, setIdtoast] = useState(1);
  const [orderFiles,SetOrderFiles] = useState([]);

  const fetchData = async () => {
    try {
      if (token && item.orderDetailId ) {
        const response = await axios.get(
          `/getOrdersFileBySO/?orderDetailId=${encodeURIComponent(item.orderDetailId)}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.data && response.data.length > 0) {
          SetOrderFiles(response.data);
                      
          const ENGOrderFile = response.data.find(file => file.fileTypeId === 1);
          const ETOOrderFile = response.data.find(file => file.fileTypeId === 2);


          if (ENGOrderFile && item.engnoteComp) {
            const fileName = ENGOrderFile.orderFileName;

            setFilepath(fileName);

            
            const filesData = [{
              name: fileName,  
              // extension: ".xlsx",                
            }];
      
            setFiles1(filesData);
            setDownload(true);
            setForceUpdate((prev) => !prev);
          }
          
          if (ETOOrderFile && item.etoproc) {
            const fileName = ETOOrderFile.orderFileName;

            setFilepath(fileName);
           
            const filesData = [{
              name: fileName,  
              // extension: ".xlsx",                
            }];
      
            setFiles2(filesData);
            setDownloadETO(true);
            setForceUpdate2((prev) => !prev);
          
        }
      }

     }        
    } catch (error) {     

      setToast({ showToast: true ,message:  error.message, type: 'error' });        
    }
  };

  useEffect(() => {
    setToast({ showToast: false });
    setIdtoast(idtoast+1);
    if(myAssignedWorkInfo && myAssignedWorkInfo?.myAssignedWork)
    {            
      setSelected(1);
    }    
    fetchData();
  }, [token]);

  const onAdd1 = async (event) => {

    const newFiles = event.newState;
    
    if (newFiles.length > 0) {
      const uploadedFile = newFiles[0];
      const fileName = uploadedFile.name;

      setFilepath(fileName);

      setFiles1(newFiles);
      setDownload(true);
      await handleFileUpload(uploadedFile,1);
    }
  };

  const handleFileUpload = async (uploadedFile, fileTypeId) => {
    const formData = new FormData();
    formData.append('file', uploadedFile.getRawFile(), uploadedFile.name);
    let errorMessage;
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    try {
      if (token && item.so) {
        const saveUrl = `/upload/blob?&departmentsFolder=${encodeURIComponent(departmentsFolder)}&departmentFolder=${encodeURIComponent(departmentFolder)}&orderNumFolder=${encodeURIComponent(item.so)}&containerName=${encodeURIComponent(containerName)}&orderFileNotes=${encodeURIComponent('')}&orderDetailId=${encodeURIComponent(item.orderDetailId)}&fileTypeId=${encodeURIComponent(fileTypeId)}&author=${encodeURIComponent(userEmail)}`;

        const response = await axios.post(saveUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          }
        });

        setFilepath(uploadedFile.name);
        setToast({ showToast: true, message: "Your file has been added successfully", type: 'success' });
        fetchData();
      }
    } catch (error) {
      if (error.response) {

        const status = error.response.status;
        const errorMessages = {
          400: 'The file already exists',
          401: 'Expired Token',
          500: 'The file already exists',
        };

        errorMessage = errorMessages[status] || 'An error occurred';
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      setToast({ showToast: true, message: errorMessage, type: 'error' });

    }
  };

  const onRemoveFile = async (removedFile) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });    

    let errorMessage;
    try {
      if (token && item.so) {
        const deleteUrl = `/delete/blob?&fileName=${encodeURIComponent(removedFile.name)}&departmentsFolder=${encodeURIComponent(departmentsFolder)}&departmentFolder=${encodeURIComponent(departmentFolder)}&orderNumFolder=${encodeURIComponent(item.so)}&containerName=${encodeURIComponent(containerName)}&orderDetailId=${encodeURIComponent(item.orderDetailId)}&author=${encodeURIComponent(userEmail)}`;
        const response = await axios.delete(deleteUrl, {
          headers: {
            Authorization: token,
          },
        });
        setToast({ showToast: true, message: "Your file has been deleted successfully", type: 'success' });
      }
    } catch (error) {
      if (error.response) {

        const status = error.response.status;
        const errorMessages = {
          401: 'Expired Token',
          500: 'The file does not exist on the blob storage'
        };

        errorMessage = errorMessages[status] || 'An error occurred';
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      setToast({ showToast: true, message: errorMessage, type: 'error' });
    }
  };

  const downloadFile = async (isETOFile) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;
    let fileName = '';

    //choose what file it is
    if (!isETOFile) {
      fileName = files1[0].name;
    }
    else {
      fileName = files2[0].name;
    }

    if (token) {
      const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(fileName)}&departmentsFolder=${encodeURIComponent(departmentsFolder)}&departmentFolder=${encodeURIComponent(departmentFolder)}&orderNumFolder=${encodeURIComponent(item.so)}&containerName=${encodeURIComponent(containerName)}&isProfileImage=${encodeURIComponent(false)}`;
      try {
        const response = await axios.get(downloadUrl, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        if (error.response) {

          const status = error.response.status;
          const errorMessages = {
            401: 'Expired Token',
            500: 'The file does not exist on the blob storage'
          };

          errorMessage = errorMessages[status] || 'An error occurred';
        } else {
          errorMessage = error.message || 'An unexpected error occurred';
        }

        setToast({ showToast: true, message: errorMessage, type: 'error' });
      }
    }
  };

  const onAdd2 = async (event) => {
    setDownloadETO(true);
    const newFiles = event.newState;
    if (newFiles.length > 0) {
      const uploadedFile = newFiles[0];
      const fileName = uploadedFile.name;

      setFilepath(fileName);
      setFiles2(newFiles);
      setDownloadETO(true);

      await handleFileUpload(uploadedFile,2);
    }
  };


  const handleDeleteFile1 = async () => {

    if (files1.length > 0) {
      await onRemoveFile(files1[0]);
    }
    uploadRef1.current.setState({ files: [] });
    setFiles1([]);
    setDownload(false);
    setModalVisible(false)
  };

  const handleDeleteFile2 = async () => {    
    if (files2.length > 0) {
      await onRemoveFile(files2[0]);
    }
    uploadRef2.current.setState({ files: [] });
    setFiles2([]);
    setDownloadETO(false);
    setModalVisible2(false)
  };

  const priorityIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <mask id="mask0_3124_7867"  maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
      <rect width="18" height="18" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_3124_7867)">
      <path d="M3.75 15.75V3H10.5L10.8 4.5H15V12H9.75L9.45 10.5H5.25V15.75H3.75ZM10.9875 10.5H13.5V6H9.5625L9.2625 4.5H5.25V9H10.6875L10.9875 10.5Z" fill="#0097E0"/>
    </g>
  </svg>
  );
  const downloadIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <mask id="mask0_3124_3599" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
      <rect width="18" height="18" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_3124_3599)">
      <path d="M9 12L5.25 8.25L6.3 7.1625L8.25 9.1125V3H9.75V9.1125L11.7 7.1625L12.75 8.25L9 12ZM4.5 15C4.0875 15 3.73438 14.8531 3.44063 14.5594C3.14688 14.2656 3 13.9125 3 13.5V11.25H4.5V13.5H13.5V11.25H15V13.5C15 13.9125 14.8531 14.2656 14.5594 14.5594C14.2656 14.8531 13.9125 15 13.5 15H4.5Z" fill="#0097E0"/>
    </g>
  </svg>
  );
  const [page, setPage] = React.useState({
    skip: 0,
    take: 20,
  });
  const handlePageChange = (e) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };
  const { skip, take } = page;

  const [commentsData, setCommentsData] = useState([]);
  const { accounts } = useMsal();

  // const removeComma = (formattedDate) => {
  //   return formattedDate.replace(',', ''); // Elimina la coma
  // };

  const getCommentsData = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    let errorMessage;        

    if (token) {
    const getCommentsUrl = `/getOrderComments?&orderNumber=${encodeURIComponent(item.esoi)}`;
    try {
        const response = await axios.get(getCommentsUrl, {
          headers: {
            Authorization: token,
          },
        });        
        const uniqueEmployeePicURLs = new Map();

        const commentsWithImagesAndFormattedDates = await Promise.all(response.data.map(async comment => {
            uniqueEmployeePicURLs.set(comment.employeePicUrl, null);
            const formattedCreationDate = formatDate(comment.creationDate, true);
            const updatedAuth = comment.updateAuth.split('.').join(' ');
            return { ...comment, formattedCreationDate, updateAuth: updatedAuth };
        }));

        // Sort comments
        commentsWithImagesAndFormattedDates.sort((a, b) => {
            const dateA = new Date(a.formattedCreationDate);
            const dateB = new Date(b.formattedCreationDate);
            return dateB - dateA;
        });

        // Fetch imageUrls for uniqueEmployeePicURLs
        await Promise.all([...uniqueEmployeePicURLs.keys()].map(async imageUrl => {
            const imgUrl = await downloadUserImg(imageUrl);
            uniqueEmployeePicURLs.set(imageUrl, imgUrl);
        }));

        // Update imageUrl for each comment based on corresponding uniqueEmployeePicURLs
        commentsWithImagesAndFormattedDates.forEach(comment => {
            const imageUrl = uniqueEmployeePicURLs.get(comment.employeePicUrl);
            comment.imageUrl = imageUrl || '/DefaultProfile.png'; // default image if not found
        });

        setCommentsData(commentsWithImagesAndFormattedDates);
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            const errorMessages = {
                401: 'Expired Token',          
            };
            errorMessage = errorMessages[status] || 'An error occurred';
        } else {
            errorMessage = error.message || 'An unexpected error occurred';
        }
        setToast({ showToast: true, message: errorMessage, type: 'error' });
    }
  }
};

 
  useEffect(() => {
    getCommentsData();
}, [token]);

const downloadUserImg = async (imageName) => {
  if (!imageName) {
    return '/DefaultProfile.png';
  }
  
  if (token) {
    const downloadUrl = `/download/blob?&fileName=${encodeURIComponent(imageName)}&departmentsFolder=${encodeURIComponent("")}&departmentFolder=${encodeURIComponent("")}&orderNumFolder=${""}&containerName=${encodeURIComponent("employeeprofileimages")}&isProfileImage=${encodeURIComponent(true)}`;  
    try {
      const response = await axios.get(downloadUrl, {
        headers: {
          Authorization: token,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);

      return imageUrl; 
    } catch (error) {
      console.error('Error downloading user image:', error);
      return null; 
    }
  }
};



const [wasSubmitted, setWasSubmitted] = useState(false);
const handleInsertComment = async (commentData) =>{  
  try {
    if (token && commentData) {            
      const enpointUrl = `/insertUpdateOrderComment?&orderCommId=${encodeURIComponent(0)}&orderCommDesc=${encodeURIComponent(commentData.Comment)}&orderDetailId=${encodeURIComponent(item.orderDetailId)}&author=${encodeURIComponent(truncateEmail(accounts[0].username,50))}`;        
      const response = await axios.post(enpointUrl, null, {
        headers: {
          Authorization: token,
        },
      });    
      if (response.status === 200)
      {
        setWasSubmitted(true);
        getCommentsData();
        commentData.Comment = '';
        setToast({ showToast: true, message: 'Comment added', type: 'success' });
        setCommentsData(prevCommentsData => {    
          return [...prevCommentsData];
        });
      }  

  }    
}  
  catch (error) {            
    setToast({ showToast: true, message: error.response, type: 'error' });        
  };          
};

const svgdowloadFile = [
  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0185 18.5722C13.4861 18.5722 13.8623 18.196 13.8623 17.7285V6.19725H9.08103C8.61697 6.19725 8.23729 5.81757 8.23729 5.3535V0.572266H1.20605C0.738483 0.572266 0.362305 0.948443 0.362305 1.41601V17.7285C0.362305 18.196 0.738483 18.5722 1.20605 18.5722H13.0185ZM13.8623 5.07226V4.85782C13.8623 4.6363 13.7744 4.42187 13.6162 4.26366L10.1744 0.818359C10.0162 0.660156 9.80174 0.572266 9.57672 0.572266H9.36228V5.07226H13.8623ZM6.69007 16.1485L3.30032 12.7841C2.94347 12.4301 3.19378 11.8222 3.69582 11.8222H5.98729V9.00975C5.98729 8.69897 6.23902 8.44725 6.54979 8.44725H7.67479C7.98556 8.44725 8.23729 8.69897 8.23729 9.00975V11.8222H10.5288C11.0308 11.8222 11.2811 12.4301 10.925 12.7841L7.53523 16.1485C7.30142 16.3809 6.92384 16.3809 6.69007 16.1485Z" fill="#0097E0"/>
  </svg>
];

const engFileUI = (props) => {
  return (
    <div className='roboto-15-400' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
    {props.files.map((file) => {
      const orderFile = orderFiles.find(orderFile => orderFile.fileTypeId === 1);
      const updatedate = orderFile ? formatDate(orderFile.updateDate, true) : null;

      return (
        <li style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}key={file.name}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
            {svgdowloadFile}
            <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0px'}}>
              <div className='blue-text'>{file.name}</div>              
              <div style={{color: '#828282', marginTop: '-5px'}}>{updatedate}</div>
            </div>
          </div>
          <Button
            disabled={disabled}
            onClick={() => setModalVisible(true)}
            className="buttons-container-button"
            svgIcon={xIcon}
            size={'xlarge'}
            style={{
              width: '30px', height: '30px',
              background: '#DCDCDC',
              borderRadius: '20px',
              display: download ? 'flex' : 'none',
            }}
          ></Button>
        </li>
      );
    })}
  </div>
);
};

const etoFileUI = (props) => {


  return (
    <div className='roboto-15-400' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      {props.files.map((file) => {
          const orderFile = orderFiles.find(orderFile => orderFile.fileTypeId === 2);
          const updatedate = orderFile ?  formatDate(orderFile.updateDate, true): null;


        return (
        <li style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}key={file.name}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px'}}>
            {svgdowloadFile}
            <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0px'}}>
              <div className='blue-text'>{file.name}</div>
              <div style={{color: '#828282', marginTop: '-5px'}}>{updatedate}</div>
            </div>
          </div>
              <Button
                disabled={disabled}
                onClick={() => setModalVisible2(true)}
                className="buttons-container-button"
                svgIcon={xIcon}
                size={'xlarge'}
                style={{
                  width: '30px', height: '30px',
                  background: '#DCDCDC',
                  borderRadius: '20px',
                  display: download ? 'flex' : 'none',
                }}
              ></Button>
        </li>
      );
    })}
  </div>
);
};

const [currentLine, setCurrentLine] = useState(getCurrentLine().ProdFamilyId);

useEffect(() => {
  const unsubscribe = subscribeToCurrentLineChanges(newCurrentLine => {
    setCurrentLine(newCurrentLine.ProdFamilyId);
  });
  return () => {
    unsubscribe();
  };
}, []);

const navigate = useNavigate();

const handleLinkClick = () => {
  navigate(`/changeorder/${item.orderDetailId}/${item.esoi}`, { state: { orderData: item } });
};

const handleLinkClickCode = () => {
  navigate(`/codesplit/${item.orderDetailId}/${item.esoi}`, { state: { orderData: item } });  
};  

  return (
    <Window
      title={`ESOI #: ${item.esoi}`}
      onClose={cancelEdit}
      visible={visible}
      className='position-fixed roboto-15-400'
      initialHeight={window.innerHeight}
      initialWidth={450}
      initialLeft={window.innerWidth - 450}
      style={{ borderRadius: '12px' }}
    >
      {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <div className='roboto-15-400'>        
        <b className='roboto-15-700' style={{ fontSize: '23px' }}>ORDER DETAIL</b>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <div><b className='roboto-15-700'>{`SO #: `}</b></div>
          <div>{item.so}</div>
          <div><b className='roboto-15-700'>{`ESOI #: `}</b></div>
          <div>{item.esoi}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '16px 0px 24px 0px' }}>
          <div style={{ padding: '2px 8px', borderRadius: '44px', background: statusColor.backgroundColor, color: statusColor.fontColor }}>{item.esoistatusDesc}</div>
          <div style={{ padding: '2px 8px', borderRadius: '44px', background: item.specialText === '' ? '#D1F3FF' : '#E4E0F8' }}>
            {item.orderType}</div>
          {/* <div style={{ padding: '2px 8px', borderRadius: '44px', border: '1px solid var(--color-brand-darkgrey)' }}>ENG: {item.engStatus}</div> */}
        </div>
        <div><b className='roboto-15-700'>{'Building Week: '}</b>{formatDate(item.buildWeek,false,true)}</div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '24px' }}>
          <div><b className='roboto-15-700'>{'Drafting Start: '}</b>{formatDate(item.draftingStartDate,false,true)}</div>
          <div><b className='roboto-15-700'>{'-'}</b></div>
          <div><b className='roboto-15-700'>{'Due Date: '}</b>{formatDate(item.draftingDueDate,false,true)}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0px'}}>
          <button
            disabled = {disabled}
            onClick={() => setModalPriorityVisible(true)}
            className='roboto-15-500 secondary-button blue-text'
            style={{ marginBottom: '24px',
            display: item.isPriority === 1 ? 'none': 'flex',
            borderRight: 'none',
            
            display: item.isPriority === 1 ? 'none': 'flex',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px'
          }}
            variant="outline-primary">
            Mark as a priority
            {priorityIcon}
            {/* Mark as a priority */}
          </button>
          <button
            disabled = {disabled}
            onClick={unmarkP}
            className='roboto-15-500 secondary-button blue-text'
            style={{ marginBottom: '24px',
            display: item.isPriority === 1 ? 'flex': 'none',
            borderRight: 'none',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px'
          }}
            variant="outline-primary">
            Unmark priority
            {priorityIcon}
            {/* UNmark as a priority */}
          </button>
          <button
            disabled = {disabled || currentLine !== 1}
            onClick={downloadSummaryFunction}
            className='roboto-15-500 secondary-button blue-text'
            style={{ marginBottom: '24px',
            display: downloadSummaryVar ? 'none': 'flex',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px'
          }}
            variant="outline-primary">
            Summary sheet
            {downloadIcon}
            {/* Generate summary */}
          </button>

        </div>
        
        <Modal
          visible={modalPriorityVisible}
          onClose={() => setModalPriorityVisible(false)}
          title='Do you want to mark this order as a priority?'
          message='This work will show as a priority for everyone that has access to the schedule.'
          onAccept={handleChange}
          type='INFORMATION'
        />
      </div>

      <div style={{ justifyContent: 'flex-start', display: 'flex', marginTop: '12px', gap: '2px', flexDirection: 'row', alignItems: 'flex-end' }}>
        <Button
          onClick={() => handleSelect({ selected: 0 })}
          className={`roboto-15-500 blue-text ${selected === 0 ? 'selected-tab2' : 'unselected-tab2'}`}
        >
          Information
        </Button>
        <Button
          onClick={() => handleSelect({ selected: 1 })}
          className={`roboto-15-500 blue-text ${selected === 1 ? 'selected-tab2' : 'unselected-tab2'}`}
        >
          Assignment
        </Button>
        <Button
          onClick={() => handleSelect({ selected: 2 })}
          className={`roboto-15-500 blue-text ${selected === 2 ? 'selected-tab2' : 'unselected-tab2'}`}
        >
          Comments
        </Button>
      </div>
      <div style={{ borderBottom: '1px solid #DEEFEF' }}></div>
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title="Information">
          <div className="container-fluid roboto-15-400 sidePanel_info-container">
            <div className="info-row"><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'GO Status (per AM report):'}</b>{item.gostatus}</div>
            <div className="info-row" style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {/* ENG NOTE FILE */}
              <b className='roboto-15-700 info-row' style={{ border: 'none', textAlign: 'left', position: 'absolute', top: 0, left: 0, marginTop: '3px' }}>
                <div style={{ zIndex: 1 }}>{'Engineering Note Completed Date:'}</div>
                <div
                  style={{
                    background: 'transparent',
                    minWidth: '88px',
                    height: '36px',
                    zIndex: -1,
                    display: download ? 'none' : 'flex',
                  }}></div>
                <Button
                  className='primary-button roboto-15-500'
                  onClick={() => downloadFile(false)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop: '5px',
                    minWidth: '89px',
                    display: download ? 'flex' : 'none',
                    zIndex: download ? 1 : -1,
                  }}
                >
                  Download
                </Button>
              </b>
              <Upload
                disabled = {disabled}
                ref={uploadRef1}
                className={disabled ? 'primary-button-disabled roboto-15-500': 'roboto-15-500'}
                selectMessageUI={() => 'Select file'}
                batch={false}
                multiple={false}
                defaultFiles={files1}
                withCredentials={false}
                files={files1}
                saveUrl={null}
                onAdd={onAdd1}
                key={forceUpdate}
                listItemUI={engFileUI}
                style={{
                  position: 'relative',
                  width: '100%',
                  zIndex: 0
                }}
              />
              <Modal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                title='Are you sure you want to delete this file?'
                message='This item will be deleted permanently. You cannot undo this action.'
                onAccept={handleDeleteFile1}
                type='DELETE'
              />
            </div>
            {/* ETO FILE */}
            {item.itemType !== 'FIN' && //only show if order is not standard
            <div className="info-row" style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <b className='roboto-15-700 info-row' style={{ border: 'none', textAlign: 'left', position: 'absolute', top: 0, left: 0, marginTop: '3px' }}>
                <div style={{ zIndex: 1 }}>{'ETO Processing:'}</div>
                <div
                  style={{
                    background: 'transparent',
                    minWidth: '88px',
                    height: '36px',
                    zIndex: -1,
                    display: downloadETO ? 'none' : 'flex',
                  }}></div>
                <Button
                  className='primary-button roboto-15-500'
                  onClick={() => downloadFile(true)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop: '5px',
                    minWidth: '89px',
                    display: downloadETO ? 'flex' : 'none',
                    zIndex: downloadETO ? 1 : -1,
                  }}
                >
                  Download
                </Button>
              </b>
              <Upload
                disabled = {disabled}
                ref={uploadRef2}
                className={disabled ? 'primary-button-disabled roboto-15-500': 'roboto-15-500'}
                selectMessageUI={() => 'Select file'}
                batch={false}
                multiple={false}
                defaultFiles={files2}
                withCredentials={false}
                files={files2}
                onAdd={onAdd2}
                saveUrl={null}
                key={forceUpdate2}
                listItemUI={etoFileUI}
                style={{
                  position: 'relative',
                  width: '100%',
                  zIndex: 0
                }}
              />
              <Modal
                visible={modalVisible2}
                onClose={() => setModalVisible2(false)}
                title='Are you sure you want to delete this file?'
                message='This item will be deleted permanently. You cannot undo this action.'
                onAccept={handleDeleteFile2}
                type='DELETE'
              />
            </div>
            }
            <div className="info-row"><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'Description: '}</b>{item.description}</div>
            <div className="info-row"><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'Most recent C/O: '}</b>
              <div style={{gap: '8px', display: 'flex'}} title={changesText}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 1C5.032 1 1 5.032 1 10C1 14.968 5.032 19 10 19C14.968 19 19 14.968 19 10C19 5.032 14.968 1 10 1ZM10.9 14.5H9.1V9.1H10.9V14.5ZM10.9 7.3H9.1V5.5H10.9V7.3Z" fill="#FF4949"/>
                </svg>
                <a style={{ textDecoration: 'underline', color: '#0097E0', background: 'transparent', cursor: 'pointer' }}
                  onClick={handleLinkClick}>
                  {formatDate(changeOrderDate, false, 2)}
                </a>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', wordBreak: 'break-all' }}>
              <div className="info-row" style={{ borderRight: '1px solid #CECECE', paddingRight: '8px' }}><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'Load Date: '}</b>{formatDate(item.loadDate, false,true)}</div>
              <div className="info-row" style={{ paddingLeft: '8px' }}><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'Hours: '}</b>{item.hrsLeft}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="info-row" style={{ borderBottom: 'none', borderRight: '1px solid #CECECE', paddingRight: '8px' }}><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'Qty: '}</b>{item.orderQuantity}</div>
              <div className="info-row" style={{ borderBottom: 'none', paddingLeft: '8px' }}><b className='roboto-15-700' style={{ textAlign: 'left' }}>{'BOM Revision: '}</b>{item.bom}</div>
            </div>
          </div>
          <div className="container-fluid roboto-15-400" style={{ flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
            <div style={{ marginTop: '16px' }}><b className='roboto-15-700'>Special text:</b></div>
            <div style={{ marginBottom: '16px', border: '1px solid #CECECE', borderRadius: '5px' }}>{item.specialText}</div>
            
            <b className='roboto-15-700'>Code:</b>
            <a className='link-text'
              onClick={handleLinkClickCode}>
              <div className='code-link'><p>{item.code}</p></div>
            </a>            
            <b style={{ display: disabled ? 'none' : 'flex'}} className='roboto-15-700'>ECO Type:</b>
            <div style={{ display: disabled ? 'none' : 'flex', flexDirection: 'row', gap: '8px', marginBottom: '20px', alignItems: 'center' }}>
              <div className='circle' style={{ background: ECOTypeColor, border: ECOTypeBorder }}></div>
              {item.ecotype}
            </div>
          </div>
        </TabStripTab>
        <TabStripTab title="Assignation">
      <Form
        initialValues={item}
        onSubmit={onSubmit}
        render={(formRenderProps) => (
          <AssignationForm
            formRenderProps={formRenderProps}
            item={item}
            cancelEdit={cancelEdit}
            onDesignerDetailChange={onDesignerDetailChange}
            onDesignerCheckerChange={onDesignerCheckerChange}
            disabled = {disabled}
            myAssignedWorkInfo={myAssignedWorkInfo}
            token={token}
          />
        )}
      />
    </TabStripTab>
        <TabStripTab title="Comments">
          <Form
            initialValues={item}
            onSubmit={(commentData) => handleInsertComment(commentData)}        
            render={(formRenderProps) => 
            <CommentsForm
              reset={wasSubmitted}
              setReset={setWasSubmitted}
              formRenderProps={formRenderProps} 
              cancelEdit={cancelEdit} 
              disabled={disabled}/>} 
            />
          {/* Comments section: */}
          <div style={{ height: '400px', overflowY: 'auto'}}>
            <ListView
              data={commentsData.slice(skip, skip + take)}
              item={(props) => {
                const { orderCommId, orderCommDesc, formattedCreationDate, updateAuth, imageUrl } = props.dataItem;
                return (
                  <div key={orderCommId} style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginBottom: '24px', alignItems: 'initial', marginLeft: '15px', marginRight: '25px' }}>
                    <img
                      style={{ width: '40px', height: '40px', borderRadius: '32px' }}
                      alt=""
                      src={imageUrl}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'initial', width: '100%' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'space-between' }}>
                        <b style={{ textAlign: 'left' }}>{updateAuth}</b>
                        <p style={{ textAlign: 'right' }}>{formattedCreationDate}</p>
                      </div>
                      <p>{orderCommDesc}</p>
                    </div>
                  </div>
                );
              }}
            />
         </div>
        <Pager
          className="k-listview-pager commentsPaginator"
          skip={skip}
          take={take}
          onPageChange={handlePageChange}
          total={commentsData.length}
          buttonCount={4}
          type="input"
          previousNext={true}
        />
        </TabStripTab>
      </TabStrip>
    </Window>
  );
};

export default SidePanel;