import React, { useState, useEffect, useCallback } from 'react';
import { downArrowName, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ColumnSelectorDialog from './ColumnSelectorDialog';
import GeneralGrid from './GeneralGrid';
import CancelledShippedGrid from './CancelledShippedGrid';
import NewOrdersGrid from './NewOrdersGrid';
import { Generalcolumns, NewOrdersColumns, CancelledShippedColumns } from './Columns';
import { Button } from '@progress/kendo-react-buttons';
import DateRangePicker from './datePicker/DateRangePicker';
import './TabStripComponent.css'
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import useProfileData from '../services/ProfileDataService';
import FilterDropdown from './FilterDropDown';
import { caretAltDownIcon, xIcon } from "@progress/kendo-svg-icons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SvgIcon } from "@progress/kendo-react-common";
import Modal from './Modal';
import Toast from './ToastNotification'; 
import CheckboxMAW from './../components/CheckboxMaw';
import { useNavigate } from 'react-router-dom';
import SavedFiltersModal from './SavedFiltersModal';


export const UnassignChips = ({ chipsData, setChipsData, isDetailer, setChipsRemoved }) => {
  const [size, setSize] = useState("medium");
  const [fillMode, setFillMode] = useState("solid");
  const [rounded, setRounded] = useState("full");

    //reset all filters by checker or detail
    const handleResetFilter = () => {
      setChipsData(prevState => {
        const removedDesignerChecker = isDetailer ? [] : prevState.designerChecker;
        const removedDesignerDetail = isDetailer ? prevState.designerDetail : [];
    
        setChipsRemoved(prevRemoved => ({
          ...prevRemoved,
          designerChecker: isDetailer
            ? prevRemoved.designerChecker
            : [
                ...(prevRemoved.designerChecker || []),
                ...removedDesignerChecker.filter(
                  chip => !(prevRemoved.designerChecker || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ],
          designerDetail: isDetailer
            ? [
                ...(prevRemoved.designerDetail || []),
                ...removedDesignerDetail.filter(
                  chip => !(prevRemoved.designerDetail || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ]
            : prevRemoved.designerDetail,
        }));
    
        return {
          ...prevState,
          designerChecker: isDetailer ? prevState.designerChecker : [],
          designerDetail: isDetailer ? [] : prevState.designerDetail,
        };
      });
    };
    
    //remove one designer
    const clearFilter = (field) => {
      setChipsData(prevState => {
        const removedDesignerChecker = isDetailer
          ? []
          : prevState.designerChecker.filter(chip => chip.designerName === field);
        const removedDesignerDetail = isDetailer
          ? prevState.designerDetail.filter(chip => chip.designerName === field)
          : [];
    
        setChipsRemoved(prevRemoved => ({
          ...prevRemoved,
          designerChecker: isDetailer
            ? prevRemoved.designerChecker
            : [
                ...(prevRemoved.designerChecker || []),
                ...removedDesignerChecker.filter(
                  chip => !(prevRemoved.designerChecker || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ],
          designerDetail: isDetailer
            ? [
                ...(prevRemoved.designerDetail || []),
                ...removedDesignerDetail.filter(
                  chip => !(prevRemoved.designerDetail || []).some(existingChip => existingChip.designerName === chip.designerName)
                )
              ]
            : prevRemoved.designerDetail,
        }));
    
        const updatedDesignerChecker = isDetailer
          ? prevState.designerChecker
          : prevState.designerChecker.filter(chip => chip.designerName !== field);
        const updatedDesignerDetail = isDetailer
          ? prevState.designerDetail.filter(chip => chip.designerName !== field)
          : prevState.designerDetail;
    
        return {
          ...prevState,
          designerChecker: updatedDesignerChecker,
          designerDetail: updatedDesignerDetail,
        };
      });       
    };

  const renderChips = (data) => {
    if (!Array.isArray(data)) {
      return null; 
    }

    const uniqueData = Array.from(new Set(data.map(item => item.designerName)))
      .map(name => {
        return data.find(item => item.designerName === name);
      });

    return uniqueData.map((item, index) => (
      <div key={index} style={{ display: 'flex', marginRight: '5px', marginTop: '10px' }} title={item.esoi}>
        <Chip
          className='ml-2 mb-2 customChipBackground roboto-15-400'
          size={size}
          fillMode={fillMode}
          rounded={rounded}
          removable={true}
          onRemove={() => clearFilter(item.designerName)}
          text={item.designerName}
        />
      </div>
    ));
  };

  return (
    <div style={{width: 'inherit'}}>
      <div style= {{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          {renderChips(chipsData)}
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}}>
          <button
            className="roboto-15-500 secondary-button blue-text"
            onClick={handleResetFilter}
          > {'Unassign all'}
          </button>
        </div>
      </div>
    </div>
  );
};


const TabStripComponent = () => {

  const storedCurrentTab = localStorage.getItem('currentTab');
  const storedCurrentDropDownFilters = localStorage.getItem('dropDownFiltersGeneralView');

  const [selected, setSelected] = useState(storedCurrentTab ? parseInt(storedCurrentTab, 10) : 2);
  const [dialogVisible, setDialogVisible] = useState(false);
  const initialColumns = selected === 1 ?[...NewOrdersColumns] : selected === 0 ? [...Generalcolumns] : [...CancelledShippedColumns];
  const [selectedColumns, setSelectedColumns] = useState(initialColumns);

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const { token } = useProfileData(false);
  const [storedColumns, setStoredColumns] = useState(null); 

  

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  }; 

  const [removeFilter, setRemoveFilter] = useState(false)
  const [dropdownFilters, setDropdownFilters] = useState( storedCurrentDropDownFilters ? JSON.parse(storedCurrentDropDownFilters) : [])

  //Add special properties like cell and header to the selected load columns
  const loadColumnsInfo = async () => {  
    
    let storedColumnsConf = null;

    if(selected === 0)
     storedColumnsConf = localStorage.getItem('columnsGeneralView');
    else if(selected === 2)
     storedColumnsConf = localStorage.getItem('columnsCanShippView');

    if (storedColumnsConf) {
      const parsedColumns = JSON.parse(storedColumnsConf);
      const updatedColumns = parsedColumns.map((column) => {
          const matchingColumn = selectedColumns.find(selectedColumn => selectedColumn.field === column.field);
          return {
              ...column,
              cell: matchingColumn ? matchingColumn.cell : null,
              header: matchingColumn ? matchingColumn.header : null
          };
      });
      
      if(initialColumns.length === updatedColumns.length)
      {
        setStoredColumns(updatedColumns);
        setSelectedColumns(updatedColumns);          
      }
      
    }
  
  }
  
  useEffect(() => {   
    if(selected !== 1) loadColumnsInfo();          
  }, [token, selected]);

  const handleSelect = (e) => {     
    setSelected(e.selected);
    resetCodeStringFilters();
    localStorage.setItem('currentTab', e.selected); 

    switch (e.selected) {
      case 0:
        if (storedColumns) {          
          setSelectedColumns(storedColumns);
        } 
        else
        {
          setSelectedColumns([...Generalcolumns]);
        }        
        handleResetTableFilters();
        break;
      case 1:
        setSelectedColumns([...NewOrdersColumns]);
        handleResetTableFilters();
        break;
      case 2:
        if (storedColumns) {
          setSelectedColumns(storedColumns);
        } 
        else
        {
          setSelectedColumns([...CancelledShippedColumns]);
        }
        handleResetTableFilters();
        break;
      default:
        break;
    }
  };
   
  let allColumnss;
  switch (selected) {
      case 0:
        if (storedColumns) {          
          
          allColumnss = storedColumns;
        } 
        else
        {
          allColumnss = Generalcolumns;
        }          
          break;
      case 1:
        // neworders
          allColumnss = NewOrdersColumns;
          break;
      case 2:
        if (storedColumns) {          
          
          allColumnss = storedColumns;
        } 
        else
        {
          allColumnss = CancelledShippedColumns;
        }
          break;
      default:
          allColumnss = Generalcolumns;
          break;
  }

  const handleSaveColumns = (columnField) => {
    setSelectedColumns((prevSelectedColumns) => {
      const newColumns = prevSelectedColumns.map((column) => {
        if (column.field === columnField) {
          return { ...column, visible: !column.visible };
        }
        return column;
      });

      if(selected === 0)
        localStorage.setItem('columnsGeneralView', JSON.stringify(newColumns, null, 2));
      else if(selected === 2)
        localStorage.setItem('columnsCanShippView', JSON.stringify(newColumns, null, 2));
      
      return newColumns;
    });
  };

  const handleResetColumns = () => {        
    switch (selected) {
      case 0:
        localStorage.setItem('columnsGeneralView', JSON.stringify(Generalcolumns, null, 2));
        loadColumnsInfo();
        setSelectedColumns([...Generalcolumns]);
        break;
      case 1:
        setSelectedColumns([...NewOrdersColumns]);
        break;
      case 2:
        localStorage.setItem('columnsCanShippView', JSON.stringify(CancelledShippedColumns, null, 2));
        loadColumnsInfo();
        setSelectedColumns([...CancelledShippedColumns]);
        break;
      default:
        break;
    }
    
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };  




  const dateFields = ["buildWeek", "draftingStartDate", "draftingDueDate", "loadDate", "promiseDate"];
  const storedFilter = localStorage.getItem('filtersGeneralView');  
  const storedCurrentFilter = localStorage.getItem('currentFilterGeneralView'); 
  const storedPrevFilter = localStorage.getItem('currentPrevFilterGeneralView');

  //Parse dates
  const parseFiltersFromStorage = (storedFilter) => {    
    if (storedFilter && storedFilter !== "null") {        
        const parsedFilter = JSON.parse(storedFilter);
        return {
            ...parsedFilter,
            filters: parsedFilter.filters?.map(filterItem => {
                return {
                    ...filterItem,
                    value: dateFields.includes(filterItem.field) ? new Date(filterItem.value) : filterItem.value
                };
            })
        };
    } else {        
        return null;
    }
};
  
  const [filter, setFilter] = useState(storedFilter ? parseFiltersFromStorage(storedFilter) : null);
  const [prevFilter, setPrevFilter] = useState(storedPrevFilter ? JSON.parse(storedPrevFilter) : []);
  const [currentFilter, setCurrentFilter] = useState(storedCurrentFilter ? JSON.parse(storedCurrentFilter) : []);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [emptyDate, setEmptyDate] = useState(startDate !== null);
  

  const handleResetTableFilters = () => {
    setFilter(null);
    setPrevFilter([]);
    setCurrentFilter([]);    
    localStorage.setItem('dropDownFiltersGeneralView', JSON.stringify([], null, 2));
    localStorage.setItem('currentFilterGeneralView', JSON.stringify([], null, 2)); 
    localStorage.setItem('currentPrevFilterGeneralView', JSON.stringify([], null, 2));
  };

  const handleResetFilter = () => {    

    handleResetTableFilters();
    setStartDate(null);
    setEndDate(null);
    setDateRange({ startDate: null, endDate: null });

    resetCodeStringFilters();

    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('dropDownFiltersGeneralView');
    localStorage.removeItem('filtersGeneralView');
    localStorage.removeItem('currentFilterGeneralView');
    localStorage.removeItem('currentPrevFilterGeneralView');
    setEmptyDate(true);
  }

  const resetCodeStringFilters = () => {    

    //Clear code string filters and chips selections
    setCodeitemsChipsData([]);
    setSelectedCodeItemsOptions([]);
    setCodeItemsFiltersSelected([]);
  }

  const [size, setSize] = React.useState("medium");
  const [fillMode, setFillMode] = React.useState("solid");
  const [rounded, setRounded] = React.useState("full");

  const groupedFilters = filter && filter.filters ? filter.filters.reduce((acc, curr) => {
    if (!acc[curr.field]) {
      acc[curr.field] = [];
    }

    let formattedValue = curr.value;
    if (typeof curr.value === 'boolean') {
      // formating boolean value
      formattedValue = curr.value ? "Yes" : "No";
    } else if (dateFields.includes(curr.field)) {
      // formating date value 
      formattedValue = new Date(curr.value) instanceof Date && !isNaN(new Date(curr.value))
        ? new Date(curr.value).toLocaleDateString('en-US')
        : curr.value;
    }

    acc[curr.field].push(formattedValue);
    return acc;
  }, {}) : {};

  const clearFilter = (field) => {    
    handleRemoveAllFilters(field);
  };

  const clearCodeItemFilter = (filterKey) => {

    // update chip list
    setCodeitemsChipsData(prevData => {
      const newData = { ...prevData };
      delete newData[filterKey]; 
      return newData;
    });
    
    //update options selected
    setCodeItemsFiltersSelected(prevFilters => {
      return prevFilters.filter(item => item.description !== filterKey);
    });

    setSelectedCodeItemsOptions(prevFilters => {
      return prevFilters.filter(item => item.description !== filterKey);
    });

  };

  const extraData = { filtersLabel: ["Active filters:"]};

  const chipsData = {
    ...extraData,
    ...groupedFilters
  };

  
const handleRemoveAllFilters = (currentFilterField) => {    
    setFilter((prevFilter) => {
      if (!prevFilter) {
        return null;
      }

      // Remove the filter related to the current custom filter field
      const updatedFilters = prevFilter.filters?.filter((filter) => filter.field !== currentFilterField) || [];

      // If there are no filters left, return null to clear the filter
      return updatedFilters.length > 0 ? { ...prevFilter, filters: updatedFilters } : null;
    });

    setRemoveFilter(true);
    
    const allFields = filter?.filters?.map(filter => filter.field) || [];
    const updatedFields = allFields.filter(item => item !== currentFilterField);
    const allFields2 = [...new Set(updatedFields)];

    setPrevFilter(allFields2);
    setDropdownFilters(filter?.filters ? filter.filters : []);

    localStorage.setItem('dropDownFiltersGeneralView', JSON.stringify(filter?.filters ? filter.filters : [], null, 2));    
    localStorage.setItem('currentPrevFilterGeneralView', JSON.stringify(allFields2, null, 2));
};

  
  const [exportExcel, setExportExcel] = useState(false);

  const fixedValues = [{
    "columnName": "checkbox",
    "uniqueValues": [
      "Assign to me as Designer for detailing",  
      "Assign to me as a Designer for checking"
        
    ]
  },
  {
      "columnName": "priority",
      "uniqueValues": [
          "Past due date",
          "Near to past due date",
          "Far from due date"
      ]
  }]

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [openUnassign, setOpenUnassign] = useState(false);
  const [unassignOptions, setUnassignOptions] = useState([]);
  const [chipsRemoved, setChipsRemoved] = useState({
    designerChecker: [],
    designerDetail: []
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [toast, setToast] = useState(null);
  const [idtoast, setIdtoast] = useState(1);
  const [isUnassign, setIsUnassign] = useState(false);
  const [codeItemsFiltersSelected, setCodeItemsFiltersSelected] = useState([]);
  const [scheduleData, setScheduleData] = useState({ TotalRows: 0, Items: [] });
  const [scheduleDataNewOrders, setScheduleDataNewOrders] = useState({ TotalRows: 0, Items: [] });
  const [codeItemsChipsData, setCodeitemsChipsData] = useState([]);

  const handleEditAssign = () => {
    setUnassignOptions([]);

    if (selectedOptions?.length > 0) {
      setOpenAssign(true);
    }
    else if (selectedOptions?.length === 0) {
      showToastSelection();
    }
    
  }

  const handleEditUnassign = () => {
    // Multiple unassign

    const designerCheckerData = selectedOptions
    .filter(option => option.designerChecker !== "Not Assigned")
    .map(option => ({
      designerName: option.designerChecker,
      esoi: option.esoi
    }));

    const designerDetailData = selectedOptions
      .filter(option => option.designerDetail !== "Not Assigned")
      .reduce((acc, option) => {
        if (!acc[option.designerDetail]) {
          acc[option.designerDetail] = [];
        }
        acc[option.designerDetail].push(option.esoi);
        return acc;
      }, {});

    const designerDetail = Object.keys(designerDetailData).map(designerName => ({
      designerName,
      esoi: designerDetailData[designerName].join(', ')
    }));

    const unassignResult = {
      designerChecker: designerCheckerData,
      designerDetail: designerDetail
    };
    setUnassignOptions(unassignResult);

    // Multiple unassign

    if (selectedOptions?.length > 0) {
      setOpenUnassign(true);
    }
    else if (selectedOptions?.length === 0) {
      showToastSelection();
    }
  }

  const [drafthoursEdit, setDrafthoursEdit] = useState(false);

  const handleAcceptUnassignation = () => {
    setIsUnassign(true);
    handleAcceptAssignation();
  };

  const handleAcceptAssignation = () => {
    setOpenAssign(false);
    setOpenUnassign(false);
    setModalVisible(false);
    setDrafthoursEdit(!drafthoursEdit);
  };
    
  const handleCancelEdit = () => {
    setOpenAssign(false);
    setOpenUnassign(false);
    setModalVisible(false);
  };

  const showToastSelection = async () => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: 'Please select at least one week to edit', type: 'info' });
  };

  const [values, setValues] = useState([]);
  const updateFilters = useCallback((field, newValue) => {
    setValues(newValue);
  }, []);


  const handleApplyCodeItemsFilter = (selectedOptions) => {
    setCodeItemsFiltersSelected(selectedOptions);     
  };

  const handleShowMessage = async (text, state) => {
    setIdtoast(idtoast + 1);
    setToast({ showToast: false });
    setToast({ showToast: true, message: text, type: state });  
  };

  const navigate = useNavigate();
  const [selectedCodeItemsOptions, setSelectedCodeItemsOptions] = useState([]);

  const [showSavedFiltersModal, setShowSavedFiltersModal] = useState(false);

  const handleOpenSaveModal = () => {
    setShowSavedFiltersModal(true);
  };

  const handleCloseSaveModal = () => {
    setShowSavedFiltersModal(false);
  };

  const toCompareCode = () => {       
    if(selectedOptions.length === 2)
    {
      navigate(`/codeCompare/${selectedOptions[0].pcl}/${selectedOptions[0].esoi}/${selectedOptions[1].pcl}/${selectedOptions[1].esoi}`, {
        state: { orderData: selectedOptions } 
      });
    }
    else
      handleShowMessage("To compare codes please select two orders" ,"info");
  };  

  return (
    <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
    {toast && <Toast key={idtoast} showToast={toast.showToast} message={toast.message} type={toast.type} />}
      <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
        <div style={{ justifyContent: 'flex-start', display: 'flex', marginTop: '12px', gap: '2px', flexDirection: 'row', alignItems: 'flex-end' }}>
          <Button
            onClick={() => handleSelect({ selected: 0 })}
            className={selected === 0 ? 'main-c-shadow selected-tab roboto-15-500' : 'main-c-shadow unselected-tab roboto-15-500 blue-text'}
          >
            General
          </Button>
          <Button
            onClick={() => handleSelect({ selected: 1 })}
            className={selected === 1 ? 'main-c-shadow selected-tab roboto-15-500' : 'main-c-shadow unselected-tab roboto-15-500 blue-text'}
          >
            New Orders
          </Button>
          <Button
            onClick={() => handleSelect({ selected: 2 })}
            className={selected === 2 ? 'main-c-shadow selected-tab roboto-15-500' : 'main-c-shadow unselected-tab roboto-15-500 blue-text'}
          >
            Canceled and shipped
          </Button>
        </div>
        {/* Display DateRangePicker and ColumnSelectorDialog */}
        {(selected === 0 || selected === 2) && (
          <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
            <DateRangePicker onDateRangeChange={handleDateRangeChange}
              emptyDate={emptyDate}
              setEmptyDate={setEmptyDate}
              startDate={startDate} setStartDate={setStartDate}
              endDate={endDate} setEndDate={setEndDate}
            ></DateRangePicker>
          {/* Display Multi-assign and unassign Button */}
            {selected === 0 && (
              <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
                <button
                  onClick={handleEditAssign}
                  style={{minWidth: '0px'}}
                  className='secondary-button roboto-15-500 blue-text'> 
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_7110_12072" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                  <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_7110_12072)">
                  <path d="M13 11.95C13.4833 11.4167 13.8542 10.8083 14.1125 10.125C14.3708 9.44167 14.5 8.73333 14.5 8C14.5 7.26667 14.3708 6.55833 14.1125 5.875C13.8542 5.19167 13.4833 4.58333 13 4.05C14 4.18333 14.8333 4.625 15.5 5.375C16.1667 6.125 16.5 7 16.5 8C16.5 9 16.1667 9.875 15.5 10.625C14.8333 11.375 14 11.8167 13 11.95ZM18.5 20V17C18.5 16.4 18.3667 15.8292 18.1 15.2875C17.8333 14.7458 17.4833 14.2667 17.05 13.85C17.9 14.15 18.6875 14.5375 19.4125 15.0125C20.1375 15.4875 20.5 16.15 20.5 17V20H18.5ZM20.5 13V11H18.5V9H20.5V7H22.5V9H24.5V11H22.5V13H20.5ZM8.5 12C7.4 12 6.45833 11.6083 5.675 10.825C4.89167 10.0417 4.5 9.1 4.5 8C4.5 6.9 4.89167 5.95833 5.675 5.175C6.45833 4.39167 7.4 4 8.5 4C9.6 4 10.5417 4.39167 11.325 5.175C12.1083 5.95833 12.5 6.9 12.5 8C12.5 9.1 12.1083 10.0417 11.325 10.825C10.5417 11.6083 9.6 12 8.5 12ZM0.5 20V17.2C0.5 16.6333 0.645833 16.1125 0.9375 15.6375C1.22917 15.1625 1.61667 14.8 2.1 14.55C3.13333 14.0333 4.18333 13.6458 5.25 13.3875C6.31667 13.1292 7.4 13 8.5 13C9.6 13 10.6833 13.1292 11.75 13.3875C12.8167 13.6458 13.8667 14.0333 14.9 14.55C15.3833 14.8 15.7708 15.1625 16.0625 15.6375C16.3542 16.1125 16.5 16.6333 16.5 17.2V20H0.5ZM8.5 10C9.05 10 9.52083 9.80417 9.9125 9.4125C10.3042 9.02083 10.5 8.55 10.5 8C10.5 7.45 10.3042 6.97917 9.9125 6.5875C9.52083 6.19583 9.05 6 8.5 6C7.95 6 7.47917 6.19583 7.0875 6.5875C6.69583 6.97917 6.5 7.45 6.5 8C6.5 8.55 6.69583 9.02083 7.0875 9.4125C7.47917 9.80417 7.95 10 8.5 10ZM2.5 18H14.5V17.2C14.5 17.0167 14.4542 16.85 14.3625 16.7C14.2708 16.55 14.15 16.4333 14 16.35C13.1 15.9 12.1917 15.5625 11.275 15.3375C10.3583 15.1125 9.43333 15 8.5 15C7.56667 15 6.64167 15.1125 5.725 15.3375C4.80833 15.5625 3.9 15.9 3 16.35C2.85 16.4333 2.72917 16.55 2.6375 16.7C2.54583 16.85 2.5 17.0167 2.5 17.2V18Z" fill="#0097E0"/>
                  </g>
                  </svg>
                  <p className='hide-class'>{'Assign'}</p>
                </button>
                <button
                  onClick={handleEditUnassign}
                  style={{minWidth: '0px'}}
                  className='secondary-button roboto-15-500 blue-text'> 
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_8061_25629" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                  <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_8061_25629)">
                  <path d="M24.5 9V11H18.5V9H24.5ZM8.5 4C6.29 4 4.5 5.79 4.5 8C4.5 10.21 6.29 12 8.5 12C10.71 12 12.5 10.21 12.5 8C12.5 5.79 10.71 4 8.5 4ZM8.5 10C7.4 10 6.5 9.1 6.5 8C6.5 6.9 7.4 6 8.5 6C9.6 6 10.5 6.9 10.5 8C10.5 9.1 9.6 10 8.5 10ZM8.5 13C5.83 13 0.5 14.34 0.5 17V20H16.5V17C16.5 14.34 11.17 13 8.5 13ZM14.5 18H2.5V17.01C2.7 16.29 5.8 15 8.5 15C11.2 15 14.3 16.29 14.5 17V18ZM13.01 4.05C13.93 5.11 14.5 6.49 14.5 8C14.5 9.51 13.93 10.89 13.01 11.95C14.97 11.7 16.5 10.04 16.5 8C16.5 5.96 14.97 4.3 13.01 4.05ZM17.03 13.83C17.92 14.66 18.5 15.7 18.5 17V20H20.5V17C20.5 15.55 18.91 14.49 17.03 13.83Z" fill="#0097E0"/>
                  </g>
                  </svg>
                  <p className='hide-class'>{'Unassign'}</p>
                </button>
              </div>
            )}
            <button
              className="roboto-15-500 secondary-button blue-text"
              onClick={() => setDialogVisible(true)}
              style={{ gap: '8px', width: 'fit-content', flexDirection: 'row', display: 'flex', alignItems: 'center', minWidth: '51px'}}
            >
              <div className='hide-class'>{'Columns'}</div><img className="settings-icon1 ml-5" alt="" src="/settings1.svg" />
            </button>
            <ColumnSelectorDialog
              visible={dialogVisible}
              onClose={handleDialogClose}
              onSave={handleSaveColumns}
              selectedColumns={selectedColumns}
              allColumns={allColumnss}
              onReset={handleResetColumns}
            />
          </div>
        )}
        {/* Display Export Button */}
        {selected === 1 && (
          <div style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
            <button
              className="roboto-15-500 secondary-button blue-text"
              onClick={() => setExportExcel(true)}
            > Export
            </button>
          </div>
        )}
      </div>
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{ border: 'none' }}
        className='main-c-shadow'
      >
        <TabStripTab title=''>
        {/* Code string functionalities */}
        <div style={{ margin: '20px 15px 15px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
          <FilterDropdown 
            onApplyFilter={handleApplyCodeItemsFilter}
            item={scheduleData.Items}
            numFiltersApplied={codeItemsChipsData}
            setSelectedOptions={setSelectedCodeItemsOptions}
            selectedOptions={selectedCodeItemsOptions}
            handleShowMessage={handleShowMessage}
          />
          <Button                                               
                  className=' roboto-15-500 blue-text return-button'
                  onClick={handleOpenSaveModal}         
                  to="/">
                  <span className='return-text'>Saved Filters</span>
                  <SvgIcon icon={caretAltDownIcon} size={'large'} color='#0097E0'></SvgIcon>                  
          </Button>
            {showSavedFiltersModal && (
              <SavedFiltersModal 
                onClose={handleCloseSaveModal}
                handleShowMessage={handleShowMessage}
                setCodeItemsFiltersSelected={setCodeItemsFiltersSelected}
                setSelectedCodeItemsOptions={setSelectedCodeItemsOptions}
              />
            )}
          </div>
          <Button                        
                  onClick={toCompareCode}          
                  className=' roboto-15-500 blue-text return-button' 
                  to="/">
                  <p className='return-text'>Compare codes</p>
          </Button>
        </div>
       {/* Active filters chips section*/}
       {(Object.keys(chipsData)?.length > 1 || Object.keys(codeItemsChipsData)?.length > 0) && ( 
    <div className='chipListContainer'> 
     <div className='d-flex align-items-center flex-wrap'>
      {/* grid filters ChipList */}
      <div className="chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap">
        <ChipList
          data={Object.keys(chipsData)}
          selection="multiple"        
          chip={(props) => (
            <div  className='chipsContainer d-flex' title={chipsData[props.dataItem].join(', ')}>
              <Chip
                className='ml-2 mb-2 customChipBackground'
                size={size}
                fillMode={fillMode}
                rounded={rounded}
                removable={(Generalcolumns.find(col => col.field === props.dataItem)) ? true : false}
                disabled={(Generalcolumns.find(col => col.field === props.dataItem)) ? false : true}
                onRemove={() => clearFilter(props.dataItem)}
                text={
                  <div>
                    <span className='roboto-15-500' style={{display: (Generalcolumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>
                      {(Generalcolumns.find(col => col.field === props.dataItem)) ? (Generalcolumns.find(col => col.field === props.dataItem)).title : "filters"}
                    </span>
                    <span className='tabSplit' style={{ display: (Generalcolumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>          
                    <span className='roboto-15-400'> 
                      {props.dataItem === "mgmt"
                        ? chipsData[props.dataItem]
                            .map(item => item === "Delta" ? "Yes" : item === "New" ? "No" : item)
                            .slice(0, 10)
                            .join(', ')
                        : chipsData[props.dataItem]
                            ?.slice(0, 10)
                            .join(', ')
                      }
                      {chipsData[props.dataItem]?.length > 10 && '...'}
                    </span>
                  </div>
                }
              />
            </div>
          )}
        />
      </div>
      
      {/* Code items chip Filters */}
      <div className={"chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap"}>
        <ChipList
          data={Object.keys(codeItemsChipsData)}
          selection="multiple"
          chip={(props) => {
            // get code item number
            const matchingItem = codeItemsFiltersSelected.find(item => item.description === props.dataItem);
            
            return (
              <div className="d-flex chipsContainer" title={codeItemsChipsData[props.dataItem]?.join(', ')}>
                <Chip
                  className="ml-2 mb-2 customChipCodeBackground"
                  size={size}
                  fillMode={fillMode}
                  rounded={rounded}
                  removable={true}
                  onRemove={() => clearCodeItemFilter(props.dataItem)}
                  text={
                    <div>                      
                      <span className="roboto-15-500">
                        {matchingItem?.fiCodeItem ? `${matchingItem.fiCodeItem}. ` : ''}{props.dataItem}
                      </span>
                      <span className="tabSplit">|</span>
                      <span className="roboto-15-400">
                        {codeItemsChipsData[props.dataItem]?.slice(0, 10).join(', ')}
                        {codeItemsChipsData[props.dataItem]?.length > 10 && '...'}
                      </span>
                    </div>
                  }
                />
              </div>
            );
          }}
        />
        </div>
      </div>

        <div>
          <button
            className="roboto-15-500 secondary-button blue-text"
            onClick={handleResetFilter}
            style={{ width: '140px', border: 'none' }}
          >
            {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
          </button>
        </div> 
      </div>
    )}

          <GeneralGrid 
            columns={selectedColumns} 
            dateRange={dateRange} 
            filter={filter} 
            setFilter={setFilter}
            prevFilter={prevFilter}
            setPrevFilter={setPrevFilter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}

            onRemoveAll={handleRemoveAllFilters}            
            removeFilter={removeFilter}
            setRemoveFilter={setRemoveFilter}
            setDropdownFilters={setDropdownFilters}
            dropdownFilters={dropdownFilters}

            selectedOptions = {selectedOptions}
            setSelectedOptions = {setSelectedOptions}
            onDrafthoursEdit={drafthoursEdit}
            multipleAssignation = {values}
            unassignDesigners = {unassignOptions} 
            chipsRemoved = {chipsRemoved} 
            setChipsRemoved = {setChipsRemoved}
            isUnassign = {isUnassign}
            setIsUnassign = {setIsUnassign}
            scheduleData= {scheduleData}
            setScheduleData= {setScheduleData}
            CodeStringFilters={codeItemsFiltersSelected}
            setCodeitemsChipsData={setCodeitemsChipsData}            
            
          />
          
        </TabStripTab>
        <TabStripTab title=''>

          {/* Code string functionalities */}
          <div style={{ margin: '20px 15px 15px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
            <FilterDropdown 
              onApplyFilter={handleApplyCodeItemsFilter}
              item={scheduleData.Items}
              numFiltersApplied={codeItemsChipsData}
              setSelectedOptions={setSelectedCodeItemsOptions}
              selectedOptions={selectedCodeItemsOptions}
              handleShowMessage={handleShowMessage}
            />
            <Button                                               
                    className=' roboto-15-500 blue-text return-button'
                    onClick={handleOpenSaveModal}         
                    to="/">
                    <span className='return-text'>Saved Filters</span>
                    <SvgIcon icon={caretAltDownIcon} size={'large'} color='#0097E0'></SvgIcon>                  
            </Button>
              {showSavedFiltersModal && (
                <SavedFiltersModal 
                  onClose={handleCloseSaveModal}
                  handleShowMessage={handleShowMessage}
                  setCodeItemsFiltersSelected={setCodeItemsFiltersSelected}
                  setSelectedCodeItemsOptions={setSelectedCodeItemsOptions}
                />
              )}
            </div>
          </div>

          {/* Active filters chips section*/}
          {(Object.keys(chipsData)?.length > 1 || Object.keys(codeItemsChipsData)?.length > 0) && (  
              <div className='chipListContainer'> 
                <div className='d-flex align-items-center flex-wrap'>
                <div className="chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap">
                  <ChipList
                    data={Object.keys(chipsData)}
                    selection="multiple"        
                    chip={(props) => (
                      <div  className='chipsContainer d-flex' title={chipsData[props.dataItem].join(', ')}>
                        <Chip
                          className='ml-2 mb-2 customChipBackground'
                          size={size}
                          fillMode={fillMode}
                          rounded={rounded}
                          removable={(NewOrdersColumns.find(col => col.field === props.dataItem)) ? true : false}
                          disabled={(NewOrdersColumns.find(col => col.field === props.dataItem)) ? false : true}
                          onRemove={() => clearFilter(props.dataItem)}
                          text={
                            <div>
                              <span className='roboto-15-500' style={{display: (NewOrdersColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>{(NewOrdersColumns.find(col => col.field === props.dataItem)) ? (NewOrdersColumns.find(col => col.field === props.dataItem)).title : "filters"}</span>                                                                    
                              <span className='tabSplit' style={{ display: (NewOrdersColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>
                              <span className='roboto-15-400'>
                                {chipsData[props.dataItem]?.slice(0, 10).join(', ')}
                                {chipsData[props.dataItem]?.length > 10 && '...'}
                              </span>
                            </div>
                          }
                        />
                      </div>
                    )}
                  />
                </div>

            {/* Code items chip Filters */}
            <div className={"chipList k-form-field-wrap d-flex flex-row align-items-center flex-wrap"}>
              <ChipList
                data={Object.keys(codeItemsChipsData)}
                selection="multiple"
                chip={(props) => {
                  // get code item number
                  const matchingItem = codeItemsFiltersSelected.find(item => item.description === props.dataItem);
                  
                  return (
                    <div className="d-flex chipsContainer" title={codeItemsChipsData[props.dataItem]?.join(', ')}>
                      <Chip
                        className="ml-2 mb-2 customChipCodeBackground"
                        size={size}
                        fillMode={fillMode}
                        rounded={rounded}
                        removable={true}
                        onRemove={() => clearCodeItemFilter(props.dataItem)}
                        text={
                          <div>                            
                            <span className="roboto-15-500">
                              {matchingItem?.fiCodeItem ? `${matchingItem.fiCodeItem}. ` : ''}{props.dataItem}
                            </span>
                            <span className="tabSplit">|</span>
                            <span className="roboto-15-400">
                              {codeItemsChipsData[props.dataItem]?.slice(0, 10).join(', ')}
                              {codeItemsChipsData[props.dataItem]?.length > 10 && '...'}
                            </span>
                          </div>
                        }
                      />
                    </div>
                  );
                }}
              />
              </div>

              </div>
              <div>
                <button
                  className="roboto-15-500 secondary-button blue-text"
                  onClick={handleResetFilter}
                  style={{ width: '140px', border: 'none' }}
                >
                  {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
                </button>
              </div> 
            </div>
          )}
          <NewOrdersGrid 
            columns={selectedColumns} 
            dateRange={dateRange} 
            filter={filter} 
            setFilter={setFilter}
            prevFilter={prevFilter}
            setPrevFilter={setPrevFilter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            disabled={true}

            onRemoveAll={handleRemoveAllFilters}            
            removeFilter={removeFilter}
            setRemoveFilter={setRemoveFilter}
            setDropdownFilters={setDropdownFilters}
            dropdownFilters={dropdownFilters}

            exportExcel={exportExcel}
            setExportExcel={setExportExcel}
            setScheduleData= {setScheduleDataNewOrders}
            CodeStringFilters={codeItemsFiltersSelected}
            setCodeitemsChipsData={setCodeitemsChipsData} 
          />
        </TabStripTab>
        <TabStripTab title=''>
          {/* Active filters chips section*/}
          {Object.keys(chipsData)?.length > 1 && ( 
            <div style={{ margin: '20px 15px 15px 15px', display: 'flex', flexDirection: 'row', gap: '10px',  justifyContent: 'space-between'}}> 
              <div style={{ display: 'flex', gap:'10px', alignItems: 'center'}}>
                <div className={"chipList k-form-field-wrap"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <ChipList
                    data={Object.keys(chipsData)}
                    selection="multiple"        
                    chip={(props) => (
                      <div style={{ display: 'flex', marginRight: '5px', marginTop: '10px' }} title={chipsData[props.dataItem].join(', ')}>
                        <Chip
                          className='ml-2 mb-2 customChipBackground'
                          size={size}
                          fillMode={fillMode}
                          rounded={rounded}
                          removable={(CancelledShippedColumns.find(col => col.field === props.dataItem)) ? true : false}
                          disabled={(CancelledShippedColumns.find(col => col.field === props.dataItem)) ? false : true}
                          onRemove={() => clearFilter(props.dataItem)}
                          text={
                            <div>
                              <span className='roboto-15-500' style={{display: (CancelledShippedColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none'}}>{(CancelledShippedColumns.find(col => col.field === props.dataItem)) ? (CancelledShippedColumns.find(col => col.field === props.dataItem)).title : "filters"}</span>
                              <span style={{ color: '#CECECE', marginLeft: '5px', marginRight: '5px', display: (CancelledShippedColumns.find(col => col.field === props.dataItem)) ? 'inline' : 'none' }}>|</span>          
                              <span className='roboto-15-400'>
                                {chipsData[props.dataItem]?.slice(0, 10).join(', ')}
                                {chipsData[props.dataItem]?.length > 10 && '...'}
                              </span>
                            </div>
                          }
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div>
                <button
                  className="roboto-15-500 secondary-button blue-text"
                  onClick={handleResetFilter}
                  style={{ width: '140px', border: 'none' }}
                >
                  {'Clear all filters'}<img className="settings-icon1 ml-5" alt="" src="/filter.svg" />
                </button>
              </div> 
            </div>
          )}         
          <CancelledShippedGrid 
            columns={selectedColumns} 
            dateRange={dateRange} 
            filter={filter} 
            setFilter={setFilter}
            prevFilter={prevFilter}
            setPrevFilter={setPrevFilter}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            disabled={true}

            onRemoveAll={handleRemoveAllFilters}            
            removeFilter={removeFilter}
            setRemoveFilter={setRemoveFilter}
            setDropdownFilters={setDropdownFilters}
            dropdownFilters={dropdownFilters}
          />
        </TabStripTab>
      </TabStrip>

      { openAssign && (
      <Dialog 
        onClose={handleCancelEdit}
        height={'auto'}
        width={450}
        >
        <div style={{padding: '8px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className='roboto-15-400'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <p className='roboto-19-700'>{'Assign'}</p>
            <div style={{width: '100%', marginBottom: '16px'}}>
              <CheckboxMAW
              label='none'
              endPointData={fixedValues}
              field='checkbox'
              onChangeFilter={updateFilters}
              />
            </div>
            <Button
              onClick={handleCancelEdit}
              style={{
                  position: 'absolute', right: '24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '180px',
                  backgroundColor: '#F2F2F2',
                  border: 'none',
                  padding: 0,
              }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
            </Button> 
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <button onClick={handleCancelEdit} className='secondary-button roboto-15-500 blue-text'>Cancel</button>
            <button onClick={() => setModalVisible(true)} className='primary-button roboto-15-500'>Accept</button>
          </div>
        </div>
      </Dialog>
      )}

      { openUnassign && (
      <Dialog 
        onClose={handleCancelEdit}
        height={'auto'}
        width={450}
        >
        <div style={{padding: '8px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className='roboto-15-400'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <p className='roboto-19-700'>{'Unassign'}</p>
            <div style={{borderTop: unassignOptions?.designerDetail?.length > 0, flexDirection: 'column', paddingBottom: '8px'}}>
              <p className='roboto-15-500' style={{paddingBottom: '8px', textAlign: 'initial'}}>{'Assigned for Checking'}</p>
              <UnassignChips
                chipsData={unassignOptions?.designerChecker}
                setChipsData={setUnassignOptions}
                setChipsRemoved={setChipsRemoved}
                isDetailer={false}
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'flex-start', width: '100%',
              borderTop: unassignOptions?.designerDetail?.length > 0  ? '1px solid #EBEBEB' : 'none', padding: '8px 0px', marginBottom: '16px',
            }}>
              <p className='roboto-15-500' style={{paddingBottom: '8px', textAlign: 'initial'}}>{'Assigned for Detailing'}</p>
              <UnassignChips
                chipsData={unassignOptions?.designerDetail}                
                setChipsData={setUnassignOptions}
                setChipsRemoved={setChipsRemoved}
                isDetailer={true}
              />
            </div>
            <Button
              onClick={handleCancelEdit}
              style={{
                  position: 'absolute', right: '24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '180px',
                  backgroundColor: '#F2F2F2',
                  border: 'none',
                  padding: 0,
              }}> <SvgIcon icon={xIcon} color='#8D8D8D'></SvgIcon>
            </Button> 
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <button onClick={handleCancelEdit} className='secondary-button roboto-15-500 blue-text'>Cancel</button>
            <button onClick={handleAcceptUnassignation} className='primary-button roboto-15-500'>Save</button>
          </div>
        </div>
      </Dialog>
      )}

      <Modal
        visible={modalVisible}
        onClose={handleCancelEdit}
        title='Are you sure you want to assign these orders to yourself?'
        message={`${selectedOptions?.length} orders will be assigned to yourself as "${values}"`}
        onAccept={handleAcceptAssignation}
        type='INFORMATION'
      />

    </div>
  );
};

export default TabStripComponent;
